<template>
  <b-col>
    <!-- form -->
    <validation-observer ref="clientDataForm">
      <b-form @submit.prevent="validationForm">
        <b-card>
          <b-row>
            <b-col sm="12">
              <b-row class="mt-2 mb-2">
                <!-- business Name -->
                <b-col md="6">
                  <b-form-group
                    label="Razón social"
                    label-for="businessName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Razón social"
                      rules="max:100"
                      vid="businessName"
                    >
                      <b-form-input
                        id="businessName"
                        v-model="businessName"
                        :state="errors.length > 0 ? false:null"
                        name="businessName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- RUC -->
                <b-col md="6">
                  <b-form-group
                    label="RUC"
                    label-for="ruc"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="RUC"
                      rules="max:11"
                      vid="ruc"
                    >
                      <b-form-input
                        id="ruc"
                        v-model="ruc"
                        :state="errors.length > 0 ? false:null"
                        name="ruc"
                        :formatter="formatterNumber"
                        readonly
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- email -->
                <b-col md="6">
                  <b-form-group
                    label="Correo electrónico"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Correo electrónico"
                      rules="email|required"
                      vid="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="email"
                        :state="errors.length > 0 ? false:null"
                        name="email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- phone number-->
                <b-col md="6">
                  <b-form-group
                    label="Teléfono Celular"
                    label-for="phoneNumber"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Teléfono Celular"
                      rules="min:9|max:15"
                      vid="phoneNumber"
                    >
                      <b-form-input
                        id="phoneNumber"
                        v-model="phoneNumber"
                        :state="errors.length > 0 ? false:null"
                        name="phoneNumber"
                        :formatter="formatterTel"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- landline-->
                <b-col md="6">
                  <b-form-group
                    label="Teléfono Fijo (Opcional)"
                    label-for="landline"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Teléfono fijo"
                      rules="max:15"
                      vid="landline"
                    >
                      <b-form-input
                        id="landline"
                        v-model="landline"
                        :state="errors.length > 0 ? false:null"
                        name="landline"
                        :formatter="formatterTel"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-row
          class="justify-content-center"
        >
          <b-col
            sm="12"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              Guardar y avanzar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormGroup, BFormInput, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [heightTransition],
  data() {
    return {
      // Options values
      isEfidePartner: 'no',
      required,
      businessName: JSON.parse(localStorage.getItem('userData')).businessName,
      ruc: JSON.parse(localStorage.getItem('userData')).ruc,
      email: JSON.parse(localStorage.getItem('userData')).email,
      phoneNumber: JSON.parse(localStorage.getItem('userData')).tel,
      landline: '',
      clientDataIsComplete: '0', // 0: No --- 1: Si
    }
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    if (dataU.clientData) {
      this.businessName = dataU.clientData.businessName
      this.ruc = dataU.clientData.ruc
      this.email = dataU.clientData.email
      this.phoneNumber = dataU.clientData.phoneNumber
      this.landline = dataU.clientData.landline
    }
  },
  methods: {
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterTel(value) {
      return value.replace(/[^0-9()+]/g, '')
    },
    validationForm() {
      this.$refs.clientDataForm.validate().then(success => {
        if (success) {
          useJwt.clientData({
            email: this.email,
            ruc: this.ruc,
            clientData: {
              businessName: this.businessName,
              ruc: this.ruc,
              email: this.email,
              phoneNumber: this.phoneNumber,
              landline: this.landline,
            },
          })
            .then(({ data }) => {
              const user = data.user.value
              localStorage.setItem('userData', JSON.stringify(user))
              store.dispatch(
                'elementVerticalMenu/setStatusStep',
                { step: 'Client-data', status: user.clientData.completed },
                { root: true },
              )
              this.$router.replace('/registro/datos-ubicacion')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Se ha guardado tu información con éxito.',
                      autoHideDelay: 5000,
                    },
                  })
                })
            })
            .catch(error => {
              console.log('errores', error)
              this.$refs.clientDataForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
