var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',[_c('validation-observer',{ref:"clientDataForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Razón social","label-for":"businessName"}},[_c('validation-provider',{attrs:{"name":"Razón social","rules":"max:100","vid":"businessName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"businessName","state":errors.length > 0 ? false:null,"name":"businessName"},model:{value:(_vm.businessName),callback:function ($$v) {_vm.businessName=$$v},expression:"businessName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"RUC","label-for":"ruc"}},[_c('validation-provider',{attrs:{"name":"RUC","rules":"max:11","vid":"ruc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ruc","state":errors.length > 0 ? false:null,"name":"ruc","formatter":_vm.formatterNumber,"readonly":""},model:{value:(_vm.ruc),callback:function ($$v) {_vm.ruc=$$v},expression:"ruc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Correo electrónico","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Correo electrónico","rules":"email|required","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Teléfono Celular","label-for":"phoneNumber"}},[_c('validation-provider',{attrs:{"name":"Teléfono Celular","rules":"min:9|max:15","vid":"phoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phoneNumber","state":errors.length > 0 ? false:null,"name":"phoneNumber","formatter":_vm.formatterTel},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Teléfono Fijo (Opcional)","label-for":"landline"}},[_c('validation-provider',{attrs:{"name":"Teléfono fijo","rules":"max:15","vid":"landline"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"landline","state":errors.length > 0 ? false:null,"name":"landline","formatter":_vm.formatterTel},model:{value:(_vm.landline),callback:function ($$v) {_vm.landline=$$v},expression:"landline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"sm":"12","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" Guardar y avanzar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }